:root {
  --black: #212121;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

.has-cursor-pointer:not([disabled]) {
  cursor: pointer;
}

.taCenter {
  text-align: center;
}

.progress-animated {
  position: relative;
}
.progress-animated:after {
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background-image: linear-gradient(
    to left,
    hsla(0, 100%, 100%, 0),
    hsla(0, 100%, 100%, 1)
  );
  background-repeat: repeat;
  animation: progress-animated infinite 2.5s ease-in-out;
  animation-fill-mode: both;
  opacity: 0.7;
  mix-blend-mode: saturation;
}

@keyframes progress-animated {
  to {
    background-position: center;
    left: 100%;
  }
}

.tags.has-addons {
  flex-wrap: nowrap;
}

.Range-Control {
  display: flex;
  padding: 20px 0;
}

.Range-Control--Select {
  min-width: 350px;
}

.Range-Control--Label {
  min-width: 100px;
  padding-right: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.tag.is-purple {
  background-color: blueviolet;
  color: white;
}
.tag.is-orange {
  background-color: orange;
  color: white;
}

.has-text-underlined {
  text-decoration: underline;
}

.modal-card-body::after {
  content: '';
  height: 200px;
  display: block;
}
