.VideoInput {
  background: white;
  position: relative;
  text-align: center;
}

.VideoInput--Overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  cursor: pointer;
}

.VideoInput--Previews {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.VideoInput--Preview {
  width: 160px;
  height: 90px;
  background-size: contain;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.2);
  background-repeat: no-repeat;
  margin: 2px;
  position: relative;
  color: white;
}

.VideoInput--Preview--Label {
  font-size: 10px;
  bottom: 5px;
  right: 5px;
  position: absolute;
}

.VideoInput--Preview .progress {
  border-radius: 0;
  position: absolute;
  opacity: 0.7;
  height: 10px;
}

.VideoInput--Close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: all 0.1s;
}

.VideoInput--Close:hover {
  transform: scale(1.5);
}

.VideoInput--FpsInput {
  vertical-align: middle;
  margin-left: 0.25em;
}
