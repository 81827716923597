/* .App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #212121;
} */

body,
html {
  height: 100%;
  margin: 0;
}

.OnlineStatus.is-danger {
  animation: OnlineStatus 0.5s ease infinite alternate;
}

@keyframes OnlineStatus {
  from {
    opacity: 0.4;
    transform: scale(0.8);
  }
}
