.Identicon {
  height: 1em;
  width: 1em;
  display: inline-block;
}

.tag.has-identicon {
  position: relative;
  padding-left: 24px;
  overflow: hidden;
}

.tag.has-identicon .Identicon {
  position: absolute;
  top: 50%;
  left: 5px;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
}
